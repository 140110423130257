(function ($) {
    $.fn.countup = function (params) {
      // make sure dependency is present
      if (typeof CountUp !== 'function') {
        console.error('countUp.js is a required dependency of countUp-jquery.js.');
        return;
      }
  
      var defaults = {
        startVal: 0,
        decimalPlaces: 0,
        duration: 4,
      };
      if (typeof params === 'number') {
          defaults.endVal = params;
        }
      else if (typeof params === 'object') {
        $.extend(defaults, params);
      }
  
      this.each(function (i, elem) {
          let newEndVal = 0;
          newEndVal = defaults.endVal ? defaults.endVal : $(elem).data('number');
          var countUp = new CountUp(elem, newEndVal, defaults);
        countUp.start();
      });
  
      return this;
    };
  
  }(jQuery));
